import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaGoogle, FaFacebook } from 'react-icons/fa';
import configUrl from '../../configUrl';
import GoogleLoginButton from '../GoogleLoginButton';
const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate(); // Initialize navigate from useNavigate
    const { login } = useAuth();

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await axios.post(`${configUrl.beBaseUrl}/api/login`, {
                email,
                password
            });
            // console.log(response.data, 'ini data response login'); // For debugging
            
            // Store token in localStorage
            localStorage.setItem('authTokenSitusNews', response.data.token);
            localStorage.setItem('authorId', response.data.user.id);            

            sessionStorage.setItem('authTokenSitusNews', response.data.token);
            sessionStorage.setItem('authorId', response.data.user.id);
            // Redirect to the dashboard
            navigate('/index');
        } catch (error) {
            console.error('Login failed:', error.response?.data?.message || error.message);
            // Handle errors here (e.g., set an error state)
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f5f5f5' }}>
            <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px', padding: '20px', backgroundColor: '#fff', borderRadius: '10px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                <h2 style={{ textAlign: 'center', color: '#005694' }}>Log in</h2>
                <p style={{ textAlign: 'center', color: '#555' }}>Log in untuk menikmati konten sesuai dengan preferensi Anda.</p>
                <div style={{ marginBottom: '15px' }}>
                    <label>Email</label>
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        style={{ width: '93%', padding: '10px', marginTop: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                        required
                    />
                </div>
                <div style={{ marginBottom: '15px' }}>
                    <label>Password</label> 
                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        style={{ width: '93%', padding: '10px', marginTop: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                        required
                    />
                </div>
                <button
                    type="submit"
                    style={{ width: '99%', padding: '10px', marginTop: '5px', borderRadius: '5px', backgroundColor: '#005694', color: '#fff', border: 'none' }}
                >
                    Log in
                </button>
                <div style={{ textAlign: 'center', marginTop: '10px' }}>
                    <p><a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>Syarat dan Ketentuan</a> dan <a href="/" style={{ color: '#007bff', textDecoration: 'none' }}>Kebijakan Privasi</a></p>
                </div>
                <div style={{ textAlign: 'center', marginTop: '20px', borderTop: '1px solid #ccc', paddingTop: '20px' }}>
                    {/* <p style={{ marginBottom: '10px' }}>atau log in dengan</p> */}
                    <div style={{marginTop: "10px"}}>
                     <GoogleLoginButton />
                     </div>
                    {/* <button
                        style={{
                            width: '100%',
                            padding: '10px',
                            borderRadius: '5px',
                            backgroundColor: '#4267B2',
                            color: '#fff',
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <FaFacebook style={{ marginRight: '8px' }} />
                        Facebook
                    </button> */}
                </div>
            </form>
        </div>
    );
};

export default Login;
